import React from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components/macro';
import { theme } from 'styled-tools';
import { Link, NavLink } from 'react-router-dom';

const UserNavDetails = styled.span`
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const MyAccountToggle = styled(DropdownToggle)`
  margin-left: 1rem;
  &&& {
    color: ${theme('colors.black')};
    &:hover {
      color: ${theme('colors.black')};
    }
  }
`;

const ProfileIcon = styled(FontAwesomeIcon).attrs({
  icon: faUserCircle,
})``;

function MyAccountMenu({ isMobile = false, toggleNav }) {
  return (
    <UncontrolledDropdown nav inNavbar>
      <MyAccountToggle nav caret>
        <ProfileIcon title='My Account' />
        <UserNavDetails>My Account</UserNavDetails>
      </MyAccountToggle>
      <DropdownMenu right>
        {!isMobile ? (
          <>
            <DropdownItem tag={Link} to='/profile'>
              My Profile
            </DropdownItem>
            <DropdownItem tag={Link} to='/logout'>
              Logout
            </DropdownItem>
          </>
        ) : (
          <>
            <DropdownItem>
              <NavLink className='nav-link' to='/profile' onClick={toggleNav}>
                My Profile
              </NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink className='nav-link' to='/logout' onClick={toggleNav}>
                Logout
              </NavLink>
            </DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
}

MyAccountMenu.propTypes = {
  isMobile: PropTypes.bool,
  toggleNav: PropTypes.func,
};

export default MyAccountMenu;
